import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { colors, breakpoints } from '../theme'

const Features = ({ features, ...props }) => (
  <StyledFeatures {...props}>
    {features.map(block => (
      <div key={block.title} className="feature-block">
        <img src={block.picture.file.url} alt={block.picture.title} />
        <div className="title">{block.title}</div>
        <div className="description">{block.text.text}</div>
      </div>
    ))}
  </StyledFeatures>
)

const StyledFeatures = styled.section`
  display: flex;
  justify-content: space-between;

  .feature-block {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    &:first-child {
      margin-right: 15px;
    }
    &:last-child {
      margin-left: 15px;
    }
  }
  img {
    width: 30px;
    background: #fff;
  }
  .title {
    color: ${colors.brand.normal};
    font-weight: 500;
    font-size: 1.2rem;
    margin: 20px 0;
    margin-bottom: 35px;
  }
  .description {
    color: ${colors.text.normal};
    font-size: 1.1rem;
    line-height: 1.6rem;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    flex-wrap: wrap;

    .feature-block {
      max-width: 450px;
      flex-shrink: 0;
      flex-basis: 100%;
      margin: 20px 0;
    }
  }
`

export default Features
export { StyledFeatures }

export const FeatureFragment = graphql`
  fragment FeatureFragment on ContentfulFeature {
    title
    text {
      text
    }
    picture {
      title
      file {
        url
      }
    }
  }
`
