import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { breakpoints, colors } from '../theme'
import FaqItem, { StyledFaqItem } from './FAQItem'

class Faq extends React.Component {
  render() {
    return (
      <StyledFaq {...this.props}>
        <h3>Les questions les plus fréquentes</h3>
        <ul>
          {this.props.questions.map(question => (
            <FaqItem
              key={question.id}
              question={question.question}
              answer={question.answer.answer}
            />
          ))}
        </ul>
      </StyledFaq>
    )
  }
}

const StyledFaq = styled.section`
  h3 {
    font-weight: 500;
    color: ${colors.text.normal};
    padding-bottom: 15px;
    border-bottom: 2px solid #ededf0;
    margin-bottom: 30px;
  }

  ul {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  ${StyledFaqItem} {
    flex-shrink: 0;
    flex-basis: calc(50% - 10px);
    &:nth-child(odd) {
      margin-right: 20px;
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    ${StyledFaqItem} {
      flex-basis: 100%;
      &:nth-child(odd) {
        margin-right: 0;
      }
    }
  }
`

export default Faq
export { StyledFaq }

export const FAQFragment = graphql`
  fragment FAQFragment on ContentfulFaq {
    id
    question
    answer {
      answer
    }
  }
`
