import React from 'react'
import { track } from '../analytics'
import { Folding, StyledFolding } from '@monbanquet/crumble'

class FaqItem extends React.Component {
  state = { toggled: false }
  toggleAnswer = expanded => {
    this.setState({ toggled: expanded })
    if (expanded) {
      track('FAQ', {
        label: this.props.question,
      })
    }
  }
  render() {
    const { question, answer } = this.props
    return (
      <Folding
        title={question}
        expanded={this.state.toggled}
        onFold={this.toggleAnswer}
      >
        {answer}
      </Folding>
    )
  }
}

export default FaqItem
export { StyledFolding as StyledFaqItem }
