import { graphql } from 'gatsby'
import React, { useContext } from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import CallToAction, { StyledCallToAction } from '../components/CallToAction'
import FullTile from '../components/FullTile'
import TileList, { StyledTileList } from '../components/TileList'
import Timeline, { StyledTimeline } from '../components/Timeline'
import Features, { StyledFeatures } from '../components/Features'
import { breakpoints, layout } from '../theme'
import Faq, { StyledFaq } from '../components/FAQ'
import { ModalContext } from '../components/context/ModalContext'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

// https://react-slick.neostack.com/docs/api#settings
const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  lazyLoad: 'progressive',
  swipeToSlide: true,
  draggable: false,
  mobileFirst: true,
  // adaptiveHeight: true,
  responsive: [
    {
      breakpoint: breakpoints.tablet + 1,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: breakpoints.mobile + 1,
      settings: {
        slidesToShow: 1,
      },
    },
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ],
}

const HowItWorksPage = ({ data: { page } }) => {
  const { dispatch: dispatchModal } = useContext(ModalContext)
  return (
    <StyledHowItWorksPage>
      <Helmet
        title={page.seoTitle}
        meta={[
          { name: 'description', content: page.seoDescription },
          { name: 'keywords', content: page.seoKeywords },
        ]}
      />
      <Navbar />
      <div className="page-content">
        <CallToAction {...page.topCta} className="header-cta" />
        <Timeline
          blocks={[
            page.timelineBlock1,
            page.timelineBlock2,
            page.timelineBlock3,
            page.timelineBlock4,
          ]}
        />
        <TileList
          settings={{
            ...settings,
            slidesToShow: 2,
          }}
          tiles={page.pictures.map(pic => ({ picture: pic, id: pic.id }))}
          renderTile={<FullTile />}
        />
        <Features features={[page.featureBlock1, page.featureBlock2]} />
        <Faq questions={page.questions} />
        <CallToAction
          {...page.bottomCta}
          ctaClicked={() =>
            dispatchModal({
              type: 'OPEN',
              analytics: { label: 'From_CtaBannerBottom' },
            })
          }
          className="bottom-cta"
        />
      </div>
      <Footer />
    </StyledHowItWorksPage>
  )
}

const StyledHowItWorksPage = styled.div`
  .page-content {
    margin-top: ${layout.navbarHeight.normal}px;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .page-content {
      margin-top: ${layout.navbarHeight.mobile}px;
    }
  }

  ${StyledCallToAction} .wrapper,
  ${StyledTimeline},
  ${StyledTileList},
  ${StyledFeatures},
  ${StyledFaq} {
    max-width: ${layout.maxWidth}px;
    width: ${layout.width};
    margin: auto;
  }

  ${StyledTimeline},
  .bottom-cta,
  ${StyledTileList},
  ${StyledFeatures},
  ${StyledFaq} {
    margin-top: 70px;
  }

  .header-cta {
    height: 350px;
  }
  .bottom-cta {
    height: 350px;
    padding-top: 0;

    .text-wrapper {
      text-align: center;
      align-items: center;
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    ${StyledTileList} {
      width: 100%;
    }

    ${StyledTimeline},
    .bottom-cta,
    ${StyledTileList},
    ${StyledFeatures},
    ${StyledFaq} {
      margin-top: 50px;
    }
  }
`

export const query = graphql`
  {
    page: contentfulHowItWorksPage(fixedId: { eq: "howitworks-1" }) {
      seoTitle
      seoDescription {
        seoDescription
      }
      seoKeywords {
        seoKeywords
      }
      topCta {
        ...CallToActionFragment
      }
      timelineBlock1 {
        ...TimelineFragment
      }
      timelineBlock2 {
        ...TimelineFragment
      }
      timelineBlock3 {
        ...TimelineFragment
      }
      timelineBlock4 {
        ...TimelineFragment
      }
      pictures {
        id
        title
        fixed(height: 700, width: 1050, quality: 90) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
      featureBlock1 {
        ...FeatureFragment
      }
      featureBlock2 {
        ...FeatureFragment
      }
      questions {
        ...FAQFragment
      }
      bottomCta {
        ...CallToActionFragment
      }
    }
  }
`

export default HowItWorksPage
