import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../theme'
import Link from './Link'

const FullTile = ({ picture, linkedPage, link, ...props }) => (
  <Link to={(linkedPage && `/${linkedPage.slug}`) || link}>
    <StyledFullTile {...props}>
      <div className="picture">
        <Img
          fixed={picture.fixed}
          alt={picture.title}
          style={imgStyle}
          loading="lazy"
        />
      </div>
    </StyledFullTile>
  </Link>
)

const imgStyle = {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}

const StyledFullTile = styled.figure`
  outline: none;
  vertical-align: middle;

  .picture {
    position: relative;
    height: 327px;
    overflow: hidden;
    border-radius: 2px;
    align-self: center;
  }

  @media (max-width: ${breakpoints.desktop}px) {
    .picture {
      height: 29vw;
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    .picture {
      height: 32vw;
    }
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .picture {
      height: 66vw;
    }
  }
`

export default FullTile
export { StyledFullTile }
