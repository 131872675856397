import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { breakpoints, colors } from '../theme'

const Timeline = ({ blocks, ...props }) => (
  <StyledTimeline {...props}>
    {blocks.map(block => (
      <div key={block.title} className="timeline-block">
        <img src={block.picture.file.url} alt={block.picture.title} />
        <div className="title">{block.title}</div>
        <div className="description">{block.subTitle}</div>
      </div>
    ))}
  </StyledTimeline>
)

const StyledTimeline = styled.section`
  display: flex;
  justify-content: space-between;

  .timeline-block {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex: 1;
    margin: 0 15px;
    max-width: 300px;

    &::after {
      position: absolute;
      top: 30px;
      content: ' ';
      border: 1px dashed #eee;
      background: #fff;
      width: 100%;
      height: 1px;
      right: -50%;
    }

    &:last-child::after {
      content: none;
    }
  }
  img {
    width: 60px;
    height: 60px;
    padding: 13px;
    border: 1px solid #eee;
    border-radius: 50px;
  }
  .title {
    color: ${colors.brand.normal};
    font-weight: 500;
    font-size: 1.2rem;
    margin-top: 10px;
    padding: 6px 0;
  }
  .description {
    color: ${colors.text.normal};
    font-size: 1.1rem;
    line-height: 1.6rem;
    padding: 6px 0;
  }

  img,
  .title,
  .description {
    background: #fff;
    z-index: 1;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    .timeline-block {
      flex-shrink: 0;
      flex-basis: 100%;
      margin: 15px 0;

      &::after {
        transform: rotate3d(0, 0, 1, 90deg);
        right: 0;
      }
    }
  }
`

export default Timeline
export { StyledTimeline }

export const TimelineFragment = graphql`
  fragment TimelineFragment on ContentfulBanner {
    title
    subTitle
    backgroundColor
    picture {
      title
      file {
        url
      }
    }
  }
`
